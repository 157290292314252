<template>
  <form autocomplete="off">
    <v-row class="bbr-ingredient--details-form">
      <v-col cols="12">
        <search-food-input
          :value="form.id"
          :label="`Search Food Entry`"
          :error-messages="form.$getError('ingredient_id')"
          :hide-details="!form.$getError('ingredient_id')"
          :isFood="true"
          @change="ingredientChanged"
          return-object
          clearable
          outlined
          flat
        />
      </v-col>

      <v-col cols="12" sm="6">
        <v-text-field
          v-model="form.serving_quantity"
          label="Serving"
          :error-messages="form.$getError('serving_quantity')"
          hide-details
          outlined
          flat
        />
      </v-col>

      <v-col cols="12" sm="6">
        <v-text-field
          v-model="form.serving_unit"
          label="Unit"
          :error-messages="form.$getError('serving_unit')"
          hide-details
          outlined
          flat
        />
      </v-col>

      <v-col cols="12">
        <search-food-swap-input
          :value="foodSwapId"
          @change="foodSwapChanged"
          :error-messages="form.$getError('food_swap_id')"
        />
      </v-col>
    </v-row>

    <v-row class="bbr-ingredient--details-form" v-if="form.raw.title">
      <v-col cols="12">
        {{ form.raw.title }}
      </v-col>

      <v-col cols="12" sm="3">
        <v-text-field
          v-model.number="form.raw_serving_quantity"
          label="Serving"
          :error-messages="form.$getError('raw_serving_quantity')"
          hide-details
          outlined
          flat
        />
      </v-col>

      <v-col cols="12" sm="3">
        <v-text-field
          v-model="form.raw_serving_unit"
          label="Unit"
          :error-messages="form.$getError('raw_serving_unit')"
          hide-details
          outlined
          flat
        />
      </v-col>
    </v-row>
    <v-divider v-if="form.raw.title" />
  </form>
</template>

<script>
import Food from '@/models/Food'
import FoodSwap from '@/models/FoodSwap'
import SearchFoodInput from '@/components/elements/foods/SearchFoodInput2'
import SearchFoodSwapInput from '@/components/elements/foods/SearchFoodSwapInput'
import Form from '@/utils/form'
import isNil from 'lodash/isNil'

export default {
  name: 'FoodIngredientForm',

  props: {
    isNew: {
      type: Boolean,
      default: true,
    },

    formData: {
      type: Form,
      required: true,
    },
  },

  components: {
    SearchFoodInput,
    SearchFoodSwapInput,
  },

  data() {
    return {
      form: this.formData,
    }
  },

  computed: {
    foodSwapId() {
      return this.form.food_ingredient.swap?.id
    },
  },

  watch: {
    formData(form) {
      this.form = form
    },
  },

  methods: {
    ingredientChanged(value) {
      if (!(value instanceof Food) || isNil(value.id)) {
        return
      }

      this.form.ingredient_id = value.id
      this.form.serving_quantity = value.serving_quantity
      this.form.serving_unit = value.serving_unit
    },

    foodSwapChanged(value) {
      if (!(value instanceof FoodSwap)) {
        return
      }

      this.form.food_swap_id = value.id
    },
  },
}
</script>
