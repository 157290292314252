<template>
  <div class="py-5">
    <template v-if="hasInstructions">
      <instruction-item
        v-for="(instruction, index) in instructions"
        :item="instruction"
        :count="index"
        :key="index"
        :food="food"
        @saved="saved"
        @changed="changed"
        @remove="remove"
      />
    </template>

    <no-list v-else details="No Instruction List" />

    <v-divider class="mt-5" />

    <div class="d-flex align-start pt-5 px-5 ">
      <div class="flex-grow-1">
        <v-textarea
          v-model="instruction"
          label="Meal Instruction"
          rows="3"
          hide-details
          full-width
          auto-grow
          clearable
          outlined
        />

        <v-btn
          class="mt-3 float-right"
          color="primary"
          @click="addInstruction"
          depressed
          text
        >
          <v-icon class="mr-3">
            {{ icons.add }}
          </v-icon>
          Add Meal Instruction
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import InstructionItem from './InstructionsListItem'
import NoList from '@/components/elements/NoList'

import { defaultTo } from 'lodash'
import { mdiPlus } from '@mdi/js'
import { mapActions } from 'vuex'

export default {
  props: {
    food: [Object],
    items: {
      type: [Array, Object],
      default: () => {
        return []
      },
    },
  },

  components: {
    InstructionItem,
    NoList,
  },

  data() {
    return {
      instruction: null,
      instructions: this.items,
      deletingInstruction: false,
      icons: {
        add: mdiPlus,
      },
    }
  },

  computed: {
    hasInstructions() {
      return !!defaultTo(this.instructions, []).length
    },
  },

  created() {
    this.instructions = defaultTo(this.items, [])
  },

  methods: {
    ...mapActions({
      updateFood: 'meals/updateFood',
    }),

    async remove(instruction) {
      this.instructions.splice(instruction.index, 1)

      await this.updateFood({
        id: this.food.id,
        title: this.food.title,
        methods: this.food.methods,
      })
    },

    saved() {
      return false
    },

    changed(instruction) {
      this.instructions.splice(instruction.index, 1, instruction.value)
    },

    async addInstruction() {
      if (!this.instruction) return

      this.instructions.push(this.instruction)
      this.instruction = null

      await this.updateFood({
        id: this.food.id,
        title: this.food.title,
        methods: this.instructions,
      })
    },
  },
}
</script>
