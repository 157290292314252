<template>
  <div class="bbr-mealguide--add-new-form">
    <v-row>
      <v-col cols="12" sm="12">
        <v-select
          v-model="form.meal_type"
          :items="mealTypes"
          item-text="name"
          item-value="id"
          label="Meal Type"
          :error-messages="form.$getError('meal_type')"
          hide-details
          outlined
          flat
        >
        </v-select>
      </v-col>

      <v-col cols="12" sm="12">
        <search-food-input
          v-show="form.meal_type === 4 || form.meal_type === 6"
          @change="changeMeal"
          :isFood="false"
          :value="form.food_id"
          :label="`Search Meal`"
          :objects="true"
          :error-messages="form.$getError('food_id')"
          hide-details
          outlined
          flat
        />

        <v-textarea
          v-show="form.meal_type !== 4 && form.meal_type !== 6"
          v-model="form.food_name"
          label="Notes or Description"
          rows="3"
          :error-messages="form.$getError('food_name')"
          :hide-details="!form.$getError('food_name')"
          auto-grow
          outlined
          flat
        />
      </v-col>
    </v-row>

    <v-row v-if="form.meal_type === 4 || form.meal_type === 6">
      <v-col cols="12" sm="6">
        <v-text-field
          v-model="form.serving_quantity"
          label="Quantity"
          :error-messages="form.$getError('serving_quantity')"
          outlined
          flat
        />
      </v-col>

      <v-col cols="12" sm="6">
        <v-text-field
          v-model="form.serving_unit"
          label="Unit"
          :error-messages="form.$getError('serving_unit')"
          outlined
          flat
        />
      </v-col>
    </v-row>

    <v-row v-if="form.food_image_public_id">
      <v-col cols="12" sm="12">
        <v-text-field
          v-model="form.food_image_public_id"
          label="Image Public ID"
          :error-messages="form.$getError('food_image_public_id')"
          outlined
          flat
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import SearchFoodInput from '@/components/elements/foods/SearchFoodInput2'
import { mapState } from 'vuex'
import Form from '@/utils/form'
import isNil from 'lodash/isNil'
import isNumber from 'lodash/isNumber'

export default {
  name: 'MealGuideForm',

  props: {
    image: {
      type: Object,
    },
    formData: {
      type: Form,
      required: true,
    },
  },

  components: { SearchFoodInput },

  data() {
    return {
      form: this.formData,
    }
  },

  watch: {
    formData() {
      this.form = this.formData
    },
  },

  computed: {
    ...mapState({
      mealTypes: (state) => state.meals.extra.mealTypes,
    }),
  },

  methods: {
    changeMeal(value) {
      if (isNil(value) || isNumber(value)) {
        return
      }

      // eslint-disable-next-line camelcase
      const { id, title, serving_unit } = value

      if (!isNil(id)) {
        this.form.food_id = id
        this.form.food_name = title

        // eslint-disable-next-line camelcase
        this.form.serving_unit = serving_unit
      }
    },
  },
}
</script>
