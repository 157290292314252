<template>
  <div class="meal-guide">
    <div v-if="form">
      <v-row>
        <v-col sm="8">
          <div class="mb-4 d-flex justify-space-between align-center">
            <h2 class="mr-auto">Meal Guide</h2>

            <v-btn
              class="mr-5 primary--text"
              text
              depressed
              @click="$refs.saveMeal.open()"
            >
              <v-icon>{{ icons.plus }}</v-icon>
              <span class="ml-3">Add New Meal</span>
            </v-btn>

            <v-btn
              class="px-12 bg-primary-gradient primary next-button"
              @click="nextTab"
              text
              depressed
            >
              Next
              <v-icon>{{ icons.arrowRight }}</v-icon>
            </v-btn>
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="8">
          <div v-if="meals.length > 0">
            <meals-list
              :meals="meals"
              :mealTypes="mealTypes"
              @edit="editMeal"
              @delete="deleteMeal"
              @create-ingredient="createIngredient"
              @edit-ingredient="editIngredient"
              @delete-ingredient="deleteIngredient"
              @create-flavour-booster="createFlavourBooster"
              @edit-flavour-booster="editFlavourBooster"
              @delete-flavour-booster="deleteFlavourBooster"
            />
          </div>

          <no-list v-else :width="400" details="No Meals" />
        </v-col>
      </v-row>
    </div>

    <confirm-dialog ref="confirmDeleteIngredient">
      <template v-slot:cancel>
        <v-btn
          color="grey"
          text
          @click.native="$refs.confirmDeleteIngredient.cancel()"
        >
          No
        </v-btn>
      </template>
    </confirm-dialog>

    <confirm-dialog ref="confirmDeleteFlavourBooster">
      <template v-slot:cancel>
        <v-btn
          color="grey"
          text
          @click.native="$refs.confirmDeleteFlavourBooster.cancel()"
        >
          No
        </v-btn>
      </template>
    </confirm-dialog>

    <meal-guide-dialog
      ref="saveMeal"
      :form-data="form"
      @created="storeMeal"
      @updated="updateMeal"
      @cancelled="resetForm"
    />

    <food-ingredient-dialog
      ref="saveIngredient"
      :form-data="ingredientForm"
      @stored="storeIngredient"
      @updated="updateIngredient"
      @cancelled="resetIngredientForm"
    />

    <confirm-dialog ref="confirmDelete">
      <template v-slot:cancel>
        <v-btn color="grey" text @click.native="$refs.confirmDelete.cancel()">
          No
        </v-btn>
      </template>
    </confirm-dialog>

    <toast ref="toast" />
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import { mdiArrowRight, mdiPlus } from '@mdi/js'
import assignIn from 'lodash/assignIn'
import get from 'lodash/get'
import pick from 'lodash/pick'
import NoList from '@/components/elements/NoList'
import Toast from '@/components/elements/Toast'
import ConfirmDialog from '@/components/modals/ConfirmDialog'
import FoodIngredientDialog from '@/components/modals/foods/FoodIngredientDialog'
import MealGuideDialog from '../components/MealGuideDialog'
import MealsList from '../components/MealsList'
import Form from '@/utils/form'
import Food from '@/models/Food'

export default {
  name: 'MealGuideTab',

  components: {
    NoList,
    Toast,
    ConfirmDialog,
    FoodIngredientDialog,
    MealsList,
    MealGuideDialog,
  },

  data() {
    return {
      loading: false,
      icons: {
        plus: mdiPlus,
        arrowRight: mdiArrowRight,
      },
      food: null,
      updateableBooster: -1,
      form: new Form(defaultValues.meal),
      ingredientForm: new Form(defaultValues.ingredient),
      flavourBoosterForm: new Form(defaultValues.flavourBooster),
    }
  },

  computed: {
    ...mapState({
      mealTypes: (state) => state.meals.extra.mealTypes,
      meals: (state) => state.meals.selectedData.meals ?? [],
    }),

    mealTime() {
      return this.$route.meta ? this.$route.meta.meal_time : 1
    },

    stage() {
      return this.$route.meta ? this.$route.meta.stage : 2
    },

    mealResource() {
      const resource = this.form.$data()

      resource.meal_guide_id = this.$attrs.id
      resource.has_food = false
      resource.meal_time = this.mealTime

      if (resource.meal_type === 4 || resource.meal_type === 6) {
        resource.has_food = true
      }

      return resource
    },

    ingredientResource() {
      const resource = this.ingredientForm.$data()

      resource.ingredient_id = this.ingredientForm.ingredient_id
      resource.food_swap_id = this.ingredientForm.food_swap_id

      return resource
    },
  },

  methods: {
    ...mapActions({
      getSingleData: 'meals/getSingleData',
      saveData: 'meals/saveData',
      _getMeals: 'meals/getMeals',
      _createMeal: 'meals/createMeal',
      _updateMeal: 'meals/updateMeal',
      _deleteMeal: 'meals/deleteMeal',
      _createIngredient: 'meals/createIngredient',
      _updateIngredient: 'meals/updateIngredient',
      _deleteIngredient: 'meals/deleteIngredient',
      updateFood: 'meals/updateFood',
    }),

    ...mapMutations({
      clearList: 'meals/clearList',
    }),

    async fetchData(id) {
      const mealTime = this.mealTime

      this.loading = true
      await this._getMeals({ id, mealTime })
      this.loading = false
    },

    async storeMeal() {
      try {
        await this._createMeal(this.mealResource)
        await this.fetchData(this.$attrs.id)
        this.resetForm()
        this.$refs.saveMeal.close()
        this.sendSuccessMessage('Meal successfully added!')
      } catch (error) {
        this.setErrors(error.response)
      }
    },

    async editMeal(meal) {
      this.fillForm(meal)
      this.$refs.saveMeal.open(false)
    },

    async updateMeal() {
      try {
        await this._updateMeal(this.mealResource)

        if (this.mealResource.food_image_public_id) {
          const foodImageData = {
            id: this.mealResource.food_id,
            title: this.mealResource.food_title,
            image_public_id: this.mealResource.food_image_public_id,
          }
          await new Food(foodImageData).save()
        }

        await this.fetchData(this.$attrs.id)

        this.resetForm()
        this.$refs.saveMeal.close()
        this.sendSuccessMessage('Meal successfully updated!')
      } catch (error) {
        this.setErrors(error.response)
      }
    },

    async deleteMeal(meal) {
      const confirm = await this.$refs.confirmDelete.open(
        'Delete Meal',
        'You are deleting a meal, continue?'
      )

      if (confirm) {
        meal.meal_guide_id = this.$attrs.id
        await this._deleteMeal(meal)
        await this.fetchData(this.$attrs.id)
        this.sendSuccessMessage('Meal successfully removed!')
      }
    },

    async createIngredient(food) {
      if (food) {
        this.food = food

        this.$refs.saveIngredient.open()
      }
    },

    async storeIngredient() {
      try {
        await this._createIngredient({
          ...this.ingredientResource,
          foodId: this.food.id,
        })

        await this.fetchData(this.$attrs.id)

        this.resetIngredientForm()
        this.$refs.saveIngredient.close()

        this.sendSuccessMessage('Ingredient successfully added!')
      } catch (error) {
        this.setErrors(error.response, 'ingredientForm')
      }
    },

    async editIngredient(ingredient, food) {
      if (ingredient && food) {
        this.fillIngredientForm(ingredient)
        this.food = food
        this.$refs.saveIngredient.open(false)
      }
    },

    async updateIngredient() {
      try {
        await this._updateIngredient({
          ...this.ingredientResource,
          foodId: this.food.id,
          id: this.ingredientForm.food_ingredient.id,
        })

        await this.fetchData(this.$attrs.id)

        this.resetIngredientForm()
        this.$refs.saveIngredient.close()

        this.sendSuccessMessage('Ingredient successfully updated!')
      } catch (error) {
        this.setErrors(error.response, 'ingredientForm')
      }
    },

    async deleteIngredient(ingredient, food) {
      const confirm = await this.$refs.confirmDeleteIngredient.open(
        'Delete Ingredient',
        'You are deleting an ingredient, continue?'
      )

      if (confirm) {
        await this._deleteIngredient({
          foodId: food.id,
          id: ingredient.food_ingredient.id,
        })

        await this.fetchData(this.$attrs.id)

        this.sendSuccessMessage('Ingredient successfully removed!')
      }
    },

    async createFlavourBooster(food) {
      if (food) {
        this.food = food
        this.$refs.saveFlavourBooster.open()
      }
    },

    async storeFlavourBooster() {
      try {
        if (!this.flavourBoosterForm.flavour_booster) return

        await this.updateFood({
          id: this.food.id,
          title: this.food.title,
          flavour_boosters: (this.food.flavour_boosters || [])
            .splice(0)
            .concat(this.flavourBoosterForm.flavour_booster),
        })

        await this.fetchData(this.$attrs.id)

        this.resetFlavourBoosterForm()
        this.$refs.saveFlavourBooster.close()

        this.sendSuccessMessage('Flavour booster successfully added!')
      } catch (error) {
        this.setErrors(error.response, 'flavourBoosterForm')
      }
    },

    async editFlavourBooster(booster, key, food) {
      this.flavourBoosterForm.flavour_booster = booster
      this.updateableBooster = key
      this.food = food

      this.$refs.saveFlavourBooster.open(false)
    },

    async updateFlavourBooster() {
      try {
        if (!this.flavourBoosterForm.flavour_booster) return

        await this.updateFood({
          id: this.food.id,
          title: this.food.title,
          flavour_boosters: (this.food.flavour_boosters || [])
            .splice(0)
            .filter((_, index) => index !== this.updateableBooster)
            .concat(this.flavourBoosterForm.flavour_booster),
        })

        await this.fetchData(this.$attrs.id)

        this.resetFlavourBoosterForm()
        this.$refs.saveFlavourBooster.close()

        this.sendSuccessMessage('Flavour booster successfully updated!')
      } catch (error) {
        this.setErrors(error.response, 'flavourBoosterForm')
      }
    },

    async deleteFlavourBooster(key, food) {
      const confirm = await this.$refs.confirmDeleteFlavourBooster.open(
        'Delete Flavour booster',
        'You are deleting a flavour booster, continue?'
      )

      if (confirm) {
        await this.updateFood({
          id: food.id,
          title: food.title,
          flavour_boosters: (food.flavour_boosters || [])
            .splice(0)
            .filter((_, index) => index !== key),
        })

        await this.fetchData(this.$attrs.id)

        this.sendSuccessMessage('Flavour booster successfully removed!')
      }
    },

    fillForm(data) {
      data = pick(data, [
        'id',
        'meal_guide_id',
        'serving_quantity',
        'serving_unit',
        'order',
        'food_name',
        'attributes',
        'meal_type',
        'meal_time',
        'food',
      ])

      if (data.food) {
        data.food_id = data.food.id
        data.food_title = data.food.title
        data.food_image_public_id = data.food.cloudinary_image.public_id
        delete data.food
      } else {
        data.food_id = null
      }

      this.form = new Form(data)
    },

    fillIngredientForm(data) {
      data = pick(data, [
        'id',
        'title',
        'raw',
        'food_ingredient',
        'completed',
        'methods',
      ])

      data.raw = {
        food_id: get(data.raw, 'id', null),
        title: get(data.raw, 'title', null),
        serving_quantity: get(data.raw, 'serving_quantity', null),
        serving_unit: get(data.raw, 'serving_unit', null),
      }

      data.serving_quantity = data.food_ingredient.serving_quantity
      data.serving_unit = data.food_ingredient.serving_unit

      data.raw_serving_quantity = data.food_ingredient.raw_serving_quantity
      data.raw_serving_unit = data.food_ingredient.raw_serving_unit

      if (data.food_ingredient.swap) {
        data.food_swap_id = data.food_ingredient.swap.id
      }

      this.ingredientForm = new Form(data)
    },

    setErrors(response, formKey = 'form') {
      this[formKey].$timeout(() => {
        this[formKey].$busy = false

        if (response && response.status === 422) {
          this[formKey].$setErrors(response.data.errors)
        }
      })
    },

    resetForm() {
      this.form = new Form({
        ...defaultValues.meal,
        meal_guide_id: this.$attrs.id,
      })
    },

    resetIngredientForm() {
      this.food = null
      this.ingredientForm = new Form(defaultValues.ingredient)
    },

    resetFlavourBoosterForm() {
      this.food = null
      this.flavourBoosterForm = new Form(defaultValues.flavourBooster)
    },

    sendSuccessMessage(message) {
      this.$refs.toast.open({
        color: 'green',
        message,
      })
    },

    nextTab() {
      let args = { name: 'form.meals.guide' }

      if (this.$attrs.id) {
        args = assignIn(args, { params: { id: this.$attrs.id } })
      }

      if (this.stage === 2) {
        args = { name: 'form.meals.preview' }
        if (this.$attrs.id) {
          args = assignIn(args, { params: { id: this.$attrs.id } })
        }
      }

      this.$router.push(args)
    },
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (vm.$attrs.id) {
        vm.fetchData(vm.$attrs.id)
      }
    })
  },
}

const defaultValues = {
  meal: {
    serving_quantity: null,
    serving_unit: null,
    order: 0,
    food_id: null,
    food_name: null,
    food_title: null,
    food_image_public_id: null,
    attributes: null,
    meal_type: null,
    meal_time: null,
  },

  ingredient: {
    ingredient_id: null,
    food_swap_id: null,
    title: null,
    serving_quantity: 0,
    serving_unit: null,
    raw: {
      food_id: null,
      title: null,
      serving_quantity: null,
      serving_unit: null,
    },
    food_ingredient: {
      id: null,
    },
    raw_serving_quantity: null,
    raw_serving_unit: null,
    completed: false,
  },

  flavourBooster: {
    flavour_booster: '',
  },
}
</script>
