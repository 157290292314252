<template>
  <div>
    <v-simple-table v-if="hasIngredients">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left" style="width: 50%;">
              Ingredient
            </th>
            <th class="text-left">Serving</th>
            <th class="text-left">Swap</th>
            <th style="width: 20%;"></th>
          </tr>
        </thead>

        <tbody>
          <tr :key="index" v-for="(ingredient, index) in food.ingredients">
            <td>{{ ingredient.title }}</td>
            <td>
              <span v-text="ingredient.food_ingredient.serving_quantity" />
              <span v-text="ingredient.food_ingredient.serving_unit" />
            </td>
            <td>
              {{ $get(ingredient.food_ingredient.swap, 'name') }}
            </td>
            <td class="text-right">
              <v-btn
                @click="$emit('edit-ingredient', ingredient, food)"
                color="primary"
                icon
              >
                <v-icon>{{ icons.edit }}</v-icon>
              </v-btn>

              <v-btn
                @click="$emit('delete-ingredient', ingredient, food)"
                color="primary"
                icon
              >
                <v-icon>{{ icons.delete }}</v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <no-list v-else details="No Ingredients" />

    <v-divider />

    <div class="d-flex pa-5">
      <v-spacer />
      <v-btn
        v-if="food"
        @click="$emit('create-ingredient', food)"
        color="primary"
        depressed
        text
      >
        <v-icon class="mr-3"> {{ icons.add }} </v-icon>
        Add Ingredient
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mdiPlus, mdiPencil, mdiTrashCanOutline } from '@mdi/js'
import NoList from '@/components/elements/NoList'

export default {
  name: 'IngredientList',

  components: {
    NoList,
  },

  props: {
    food: Object,
  },

  data() {
    return {
      icons: {
        add: mdiPlus,
        edit: mdiPencil,
        delete: mdiTrashCanOutline,
      },
    }
  },

  computed: {
    hasIngredients() {
      return this.$get(this.food, 'ingredients', []).length > 0
    },
  },
}
</script>
