<template>
  <div class="search-food-swap-input">
    <v-autocomplete
      v-model="foodSwapId"
      :loading="loading"
      :items="items"
      item-text="name"
      item-value="id"
      :search-input.sync="search"
      flat
      outlined
      hide-no-data
      :hide-details="hideDetails"
      :error-messages="errors"
      :cache-items="true"
      :auto-select-first="true"
      :label="label"
      :return-object="returnObject"
    >
    </v-autocomplete>
  </div>
</template>

<script>
import FoodSwap from '@/models/FoodSwap'
import { debounce, isNumber } from 'lodash'

export default {
  name: 'SearchFoodSwapInput',

  props: {
    value: {
      type: Number,
    },

    returnObject: {
      type: Boolean,
      default: true,
    },

    hideDetails: {
      type: Boolean,
      default: true,
    },

    label: {
      type: String,
      default: 'Search Food Swap',
    },

    errorMessages: {
      type: Array,
    },
  },

  data() {
    return {
      search: '',
      loading: false,
      errors: [],

      items: [],
      foodSwapId: this.value,
    }
  },

  created() {
    this.errors = this.errorMessages
  },

  watch: {
    search(value) {
      value && debounce(this.fetchData, 600)(this, value)
    },

    foodSwapId(value) {
      this.$emit('change', value)
    },

    value(value) {
      value && debounce(this.fetchData, 300)(this, value)
      this.foodSwapId = this.value
    },

    errorMessages(errors) {
      this.errors = errors
    },
  },

  methods: {
    async fetchData(self, search, page = 1) {
      if (self.loading) {
        return
      }

      const query = FoodSwap.page(page)

      if (search) {
        if (isNumber(search)) {
          query.where('id', search)
        } else {
          query.where('search', search)
        }
      }

      if (self.value) {
        // Exclude currently selected food swap
      }

      const { data } = await query.params({ limit: 100 }).get()
      this.items = data

      self.loading = false
    },
  },
}
</script>
