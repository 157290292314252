<template>
  <div class="mb-10">
    <v-card :key="meal.id" v-for="(meal, key) in meals" outlined class="mb-4">
      <div class="d-flex ma-2">
        <div>
          <v-avatar tile size="130">
            <template v-if="meal.food && meal.food.cloudinary_image">
              <v-img
                :src="meal.food.cloudinary_image.medium"
                alt="meal.food.title"
                width="auto"
              />
            </template>
          </v-avatar>
        </div>

        <div class="mx-3">
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title class="text-h5 mb-1">
                <span v-if="meal.food">{{ meal.food.title }}</span>
                <span v-else>{{ meal.food_name }}</span>
              </v-list-item-title>

              <v-list-item-subtitle>
                {{ meal.meal_type | readableEnums(mealTypes) }}
              </v-list-item-subtitle>

              <v-list-item-subtitle class="green--text">
                <span v-if="meal.meal_type === 4 || meal.meal_type === 6">
                  {{ meal.serving_quantity | serving(meal.serving_unit) }}
                </span>
                ,
                <span v-if="meal.food" class="red--text">
                  <b>{{ meal.food.calories }}</b> Calories
                </span>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </div>

        <v-spacer />

        <div>
          <v-menu
            class="ml-auto"
            :position-y="100"
            rounded="lg"
            offset-y
            bottom
            left
          >
            <template v-slot:activator="{ attrs, on }">
              <v-btn class="ma-2" v-bind="attrs" v-on="on" icon>
                <v-icon>{{ icons.options }}</v-icon>
              </v-btn>
            </template>

            <v-list class="pa-0">
              <v-list-item class="px-5" link @click="$emit('edit', meal)">
                <v-list-item-title>Edit Meal</v-list-item-title>
              </v-list-item>

              <v-divider />

              <v-list-item class="px-5" link @click="$emit('delete', meal)">
                <v-list-item-title class="primary--text">
                  Delete Meal
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </div>

      <v-card-text class="pa-0">
        <v-divider />

        <template v-if="meal.food">
          <v-tabs v-model="tabGroups[key]" grow>
            <v-tab>Ingredients</v-tab>
            <v-tab>Flavour Boosters</v-tab>
            <v-tab>Instructions</v-tab>
          </v-tabs>

          <v-divider />

          <v-tabs-items v-model="tabGroups[key]">
            <v-tab-item>
              <ingredient-list :food="meal.food" v-on="$listeners" />
            </v-tab-item>

            <v-tab-item>
              <flavour-booster-list
                class="py-5"
                ref="booster"
                :food="meal.food"
                v-on="$listeners"
              >
                <template v-slot:add-button>
                  <v-divider />

                  <div class="d-flex pa-5">
                    <v-spacer />

                    <v-btn
                      @click="addNewBooster"
                      color="primary"
                      depressed
                      text
                    >
                      <v-icon class="mr-3">
                        {{ icons.add }}
                      </v-icon>

                      Add Flavour Booster
                    </v-btn>
                  </div>
                </template>
              </flavour-booster-list>
            </v-tab-item>

            <v-tab-item>
              <instructions-list :food="meal.food" :items="meal.food.methods" />
            </v-tab-item>
          </v-tabs-items>
        </template>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import FlavourBoosterList from '@/components/elements/foods/boosters/FlavourBoosterList'
import InstructionsList from './InstructionsList'
import IngredientList from './IngredientList'

import { mdiDotsVertical, mdiPencil, mdiPlus } from '@mdi/js'

export default {
  name: 'MealsList',

  components: {
    FlavourBoosterList,
    InstructionsList,
    IngredientList,
  },

  props: {
    meals: {
      type: Array,
      required: true,
    },

    mealTypes: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      show: true,
      tabGroups: [],
      icons: {
        options: mdiDotsVertical,
        pencil: mdiPencil,
        add: mdiPlus,
      },

      flavorBoosters: [],
    }
  },

  created() {
    this.tabGroups = Object.keys(this.meals).map(() => [
      'ingredients',
      'flavour_boosters',
      'methods',
    ])
  },

  methods: {
    addNewBooster() {
      this.$refs.booster[0].addFlavourBooster()
    },
  },

  filters: {
    serving(quantity, unit) {
      if (!quantity || !unit) return

      return `${quantity} ${unit}`
    },
  },
}
</script>
