<template>
  <v-dialog v-model="show" width="700" eager>
    <v-form autocomplete="off" @submit.prevent="submit">
      <v-card class="mx-auto" outlined>
        <v-card-title class="headline">
          {{ create ? 'Add Meal' : 'Save Meal' }}
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text class="py-5">
          <meal-guide-form :form-data="form" />
        </v-card-text>

        <v-divider />

        <v-card-actions class="px-6 pb-6 pt-5">
          <v-spacer />

          <v-btn class="px-12 error--text" text @click="cancel">
            Cancel
          </v-btn>

          <v-btn class="primary px-12" text type="submit">
            {{ create ? 'Add' : 'Save' }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import MealGuideForm from './MealGuideForm'

export default {
  name: 'MealGuideDialog',

  components: {
    MealGuideForm,
  },

  props: {
    formData: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      form: this.formData,
      show: false,
      create: false,
    }
  },

  watch: {
    formData() {
      this.form = this.formData
    },
  },

  methods: {
    open(create = true) {
      this.show = true
      this.create = create
    },

    close() {
      this.show = false
    },

    submit() {
      if (this.create) {
        this.$emit('created')
      } else {
        this.$emit('updated')
      }
    },

    cancel() {
      this.$emit('cancelled')
      this.close()
    },
  },
}
</script>
