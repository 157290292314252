<template>
  <v-dialog v-model="show" width="700" eager>
    <v-form autocomplete="off" @submit.prevent="submit">
      <v-card class="mx-auto" outlined>
        <v-card-title class="headline">
          {{ create ? 'Add New Ingredient' : 'Save Ingredient' }}
        </v-card-title>

        <v-card-text class="py-5">
          <food-ingredient-form :form-data="form" />
        </v-card-text>

        <v-divider />

        <v-card-actions class="px-6 pb-6 pt-5">
          <v-spacer />

          <v-btn class="px-12 error--text" text @click="cancel">
            Cancel
          </v-btn>

          <v-btn class="primary px-12" text type="submit">
            {{ create ? 'Add Ingredient' : 'Save Ingredient' }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import FoodIngredientForm from '@/components/forms/foods/FoodIngredientForm'

export default {
  name: 'FoodIngredientDialog',

  components: {
    FoodIngredientForm,
  },

  props: {
    formData: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      form: this.formData,
      show: false,
      create: false,
    }
  },

  watch: {
    formData(form) {
      this.form = form
    },
  },

  methods: {
    open(create = true) {
      this.show = true
      this.create = create
    },

    close() {
      this.show = false
    },

    submit() {
      if (this.create) {
        this.$emit('stored')
      } else {
        this.$emit('updated')
      }
    },

    cancel() {
      this.$emit('cancelled')
      this.close()
    },
  },
}
</script>
