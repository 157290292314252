<template>
  <div class="d-flex align-center px-5 py-2">
    <span class="pr-4"> {{ count + 1 }}. </span>

    <div v-if="!edit" style="max-width: 80%">
      {{ instruction }}
    </div>

    <div v-else style="width: 75%">
      <v-textarea
        v-model="instruction"
        label="Meal Instruction"
        rows="2"
        hide-details
        full-width
        auto-grow
        outlined
      />
    </div>

    <v-spacer class="px-4">
      <v-divider />
    </v-spacer>

    <div class="mr-auto ">
      <v-menu
        class="ml-auto"
        :position-y="100"
        rounded="lg"
        offset-y
        bottom
        left
      >
        <template v-slot:activator="{ attrs, on }">
          <v-btn
            class="ma-2"
            :loading="isDeleting || isSaving"
            v-bind="attrs"
            v-on="on"
            icon
          >
            <v-icon>{{ icons.options }}</v-icon>
          </v-btn>
        </template>

        <v-list class="pa-0">
          <v-list-item v-if="edit" @click="save" link>
            <v-list-item-title class="success--text px-5">
              Save Instruction
            </v-list-item-title>
          </v-list-item>

          <v-list-item v-if="edit" @click="cancel" link>
            <v-list-item-title class="warning--text px-5">
              Cancel Edit
            </v-list-item-title>
          </v-list-item>

          <v-list-item v-if="!edit" class="px-5" @click="alter" link>
            <v-list-item-title>Edit Instruction</v-list-item-title>
          </v-list-item>

          <v-divider />

          <v-list-item class="px-5" @click="remove" link>
            <v-list-item-title class="primary--text">
              Delete Instruction
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>

    <confirm-dialog ref="confirmDelete">
      <template v-slot:cancel>
        <v-btn color="grey" text @click.native="$refs.confirmDelete.cancel()">
          Cancel
        </v-btn>
      </template>
    </confirm-dialog>
  </div>
</template>

<script>
import ConfirmDialog from '@/components/modals/ConfirmDialog'
import { cloneDeep } from 'lodash'
import { mapActions } from 'vuex'
import {
  mdiCheck,
  mdiClose,
  mdiPencil,
  mdiPlus,
  mdiDotsVertical,
  mdiTrashCanOutline,
} from '@mdi/js'

export default {
  props: {
    food: [Object],
    item: [String],
    count: [Number],
    deleting: [Object],
  },

  components: {
    ConfirmDialog,
  },

  data() {
    return {
      edit: false,
      instruction: '',
      instructionCopy: null,
      icons: {
        add: mdiPlus,
        check: mdiCheck,
        cancel: mdiClose,
        edit: mdiPencil,
        options: mdiDotsVertical,
        delete: mdiTrashCanOutline,
      },
      isSaving: false,
      isUpdating: false,
      isDeleting: false,
    }
  },

  computed: {
    hasChanges() {
      return this.instruction !== this.instructionCopy
    },
  },

  created() {
    this.instruction = this.item
  },

  methods: {
    ...mapActions({
      updateFood: 'meals/updateFood',
    }),

    async save() {
      if (!this.hasChanges) {
        return (this.edit = false)
      }

      this.isSaving = true

      await this.updateFood({
        id: this.food.id,
        title: this.food.title,
        methods: this.food.methods,
      })

      await this.$sleep(700)

      this.isSaving = false
      this.edit = false
    },

    alter() {
      this.edit = true

      this.instructionCopy = cloneDeep(this.instruction)
    },

    async remove() {
      const confirm = await this.$refs.confirmDelete.open(
        'Delete Instruction',
        'Are you sure you want to delete the instruction?'
      )

      if (!confirm) return

      this.$emit('remove', {
        index: this.count,
        value: this.instruction,
      })
    },

    cancel() {
      this.edit = false
      this.instruction = this.instructionCopy
    },
  },

  watch: {
    item() {
      this.instruction = this.item
    },

    instruction(newValue, oldValue) {
      if (!oldValue && newValue === oldValue) return

      this.$emit('changed', {
        index: this.count,
        value: newValue,
      })
    },
  },
}
</script>
